const noTemplateSearchResultsStyles = {
  noResultsContainer: {
    paddingTop: "94px",
    textAlign: "center" as const,
    width: "100%",
  },
  noResultsSubtitle: {
    paddingTop: "40px",
    textAlign: "center" as const,
  },
  airplaneContainer: {
    bottom: "0",
    position: "absolute" as const,
    right: "0",
  },
};

export default noTemplateSearchResultsStyles;
