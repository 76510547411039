import classNames from "classnames";
import { postAsyncRequest, useUserState } from "gx-npm-lib";
import { publish, updateStateSubject } from "gx-npm-messenger-util";
import { Button, Select, SnackbarBanner, TextField, TypographyComponent } from "gx-npm-ui";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../app.context";
import { profileOptions } from "../../../lib/profile-options";
import { suggestedMarketData } from "../../../lib/suggested-market-data";
import classes from "./profile-form.module.scss";

const ProfileFormComponent = () => {
  const { t } = useTranslation();
  const { profileComplete, profile } = useUserState();
  const { setShowPersonalisationForm, templateData, setSelectedCategoryTemplates } = useContext(AppContext);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState(profile);

  const handleFormInputChange = (key: string, value: string) => {
    setFormData((prevState) => {
      if (key in prevState) {
        prevState = { ...prevState, [key]: value };
      }
      return prevState;
    });
  };

  const submitProfileDetails = async () => {
    if (JSON.stringify(formData) === JSON.stringify(profile)) {
      setShowPersonalisationForm(false);
      return;
    }
    const suggestedTemplates = templateData?.filter(
      (template) => suggestedMarketData[formData.role]?.includes(template.id)
    );
    setSelectedCategoryTemplates(suggestedTemplates);
    setShowPersonalisationForm(false);
    const response = await postAsyncRequest("/api/v2/user/profile", formData);
    if (response.status === 201) {
      updateStateSubject("RELOAD_INTERCOM_FOR_PROFILE_REFRESH", { isRefreshRequired: true });
      publish("USER_STATE_LOAD");
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    setFormData(profile);
  }, [profile]);

  const checkIfProfileIsComplete = () => {
    return !!(formData?.role && formData.title && formData.companySize && formData.industry);
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <TypographyComponent color={"gartnerBlue"} boldness={"semi"} styling={"h2"}>
          {t("Personalized market suggestions")}
        </TypographyComponent>
        <TypographyComponent rootClassName={classes.subHeading} color={"coal"} styling={"p2"} boldness={"medium"}>
          {t("Explore markets users like you are leveraging for their technology evaluations.")}
        </TypographyComponent>
      </div>
      <div className={classes.formSection}>
        <div className={classNames(classes.formRow)}>
          <Select
            label={`${t("Role")}*`}
            menuHeight="210px"
            rootClassName={classes.profileInputs}
            items={profileOptions.roleList}
            value={formData?.role}
            onChange={(event: ChangeEvent) => handleFormInputChange("role", (event.target as HTMLInputElement).value)}
          />
          <TextField
            label={`${t("Title")}*`}
            rootClassName={classes.profileInputs}
            value={formData?.title}
            onChange={(event: ChangeEvent) => handleFormInputChange("title", (event.target as HTMLInputElement).value)}
          />
        </div>
        <div className={classNames(classes.formRow)}>
          <Select
            label={`${t("Company size")}*`}
            menuHeight="210px"
            rootClassName={classes.profileInputs}
            items={profileOptions.companySizeList}
            value={formData?.companySize}
            onChange={(event: ChangeEvent) =>
              handleFormInputChange("companySize", (event.target as HTMLInputElement).value)
            }
          />
          <Select
            label={`${t("Industry")}*`}
            menuHeight="210px"
            rootClassName={classes.profileInputs}
            items={profileOptions.industryList}
            value={formData?.industry}
            onChange={(event: ChangeEvent) =>
              handleFormInputChange("industry", (event.target as HTMLInputElement).value)
            }
          />
        </div>
      </div>
      <div className={classes.footerSection}>
        <div className={classes.requiredContainer}>
          <TypographyComponent styling="p4" boldness="regular" rootClassName={classes.required}>
            {t("*Required")}
          </TypographyComponent>
        </div>
        <div className={classes.separator}>
          <hr />
        </div>
        <div className={classes.footer}>
          {profileComplete && (
            <Button
              onClick={() => setShowPersonalisationForm(false)}
              rootClassName={classNames(classes.cancelBtn, "btn-tertiary")}
            >
              {t("Cancel")}
            </Button>
          )}
          <Button
            disabled={!checkIfProfileIsComplete()}
            onClick={submitProfileDetails}
            rootClassName={classNames("btn-primary")}
          >
            {t("Get Suggested Markets")}
          </Button>
        </div>
      </div>
      <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
    </div>
  );
};

export default ProfileFormComponent;
