import { makeStyles } from "@material-ui/core";
import { FreeTrialRole, useUserState } from "gx-npm-lib";
import { FreeTrialBannerComponent, TypographyComponent } from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import HeaderIllustrationSVG from "../../assets/images/header-illustration.svg";
import styles from "./template-header.style";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const TemplateHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  return (
    <Fragment>
      {isTrialUser && <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />}
      <TypographyComponent
        styling={"h4"}
        color={"white"}
        boldness={"medium"}
        rootClassName={classNames(classes.container)}
      >
        {t("Explore market insights backed by Gartner research.")}
        <img alt={""} className={classes.cornerImage} src={HeaderIllustrationSVG} />
      </TypographyComponent>
    </Fragment>
  );
};

export default TemplateHeader;
