import React, { Fragment, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import styles from "./template-cards-title.styles";
import { SUGGESTED_CATEGORY_ID } from "../../../app.constants";
import { AppContext } from "../../../app.context";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const TemplateCardsTitle = () => {
  const { t } = useTranslation();
  const {
    isSearching,
    selectedCategoryTemplates,
    isSearchData,
    isLoadingCategories,
    categoryName,
    searchTerm,
    categoryId,
  } = useContext(AppContext);
  const classes = useStyles();
  const filterTitle = t("All markets");
  const categoryTitle = categoryName ? t("markets") : filterTitle;
  let searchTitle = t("Searching for");
  if (isSearchData && !isSearching) {
    const searchSuffix = selectedCategoryTemplates?.length === 1 ? t("market found for") : t("markets found for");
    searchTitle = `${selectedCategoryTemplates?.length || 0 + ""} ${searchSuffix}`;
  }
  return (
    <div className={classNames(classes.cardsTitleContainer)}>
      {!isLoadingCategories && (
        <Fragment>
          {!isSearchData && (
            <TypographyComponent styling={"h5"} boldness={"medium"} color={"coal"}>
              {!!categoryName && `${categoryName} `}
              {categoryId !== SUGGESTED_CATEGORY_ID && categoryTitle}
            </TypographyComponent>
          )}
          {isSearchData && (isSearching || selectedCategoryTemplates?.length > 0) && (
            <TypographyComponent styling={"h5"}>
              <TypographyComponent element={"span"} color={"iron"} boldness={"medium"}>
                {searchTitle}{" "}
              </TypographyComponent>
              <TypographyComponent element={"span"} color={"carbon"} boldness={"medium"}>
                {searchTerm}
              </TypographyComponent>
            </TypographyComponent>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default TemplateCardsTitle;
