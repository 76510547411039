import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import { ButtonLoader, TypographyComponent } from "gx-npm-ui";
import createBaseTemplateInitiative from "../../../../lib/create-base-template-initiative.lib";
import { baseTemplateCardStyles as styles } from "./base-template-card.styles";
import { AppContext } from "../../../../app.context";
import { useUserState } from "gx-npm-lib";

const useStyles = makeStyles(() => styles);
const BaseTemplateCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tech = t("Can’t find the market you need?");
  const { isEntitled } = useUserState();
  const [isLoading, setIsLoading] = useState(false);
  const {
    categoryName,
    searchTerm,
    isOtherTemplateDisabled,
    setIsOtherTemplateDisabled,
    isSearchData,
    templateData,
    categoryId,
    setIsError,
  } = useContext(AppContext);

  const setLoadingState = (state: boolean) => {
    setIsLoading(state);
    setIsOtherTemplateDisabled(state);
  };

  const handleClickStartEval = async () => {
    const config = {
      categoryId,
      categoryName: categoryName,
      isSearchData,
      name: "",
      searchResultsCount: templateData?.length || 0,
      searchTerm,
    };
    await createBaseTemplateInitiative(
      config,
      () => {
        setIsError(true);
      },
      setLoadingState
    );
  };

  return (
    <Grid item md={12} lg={6} xl={6}>
      <div>
        <div aria-label={t("base template card")} className={classNames("card", classes.container)}>
          <div className={classNames("gx-temp-card-body", classes.bodyContainer)}>
            <TypographyComponent
              styling={"h5"}
              color={"carbon"}
              rootClassName={classNames(classes.tech)}
              boldness={"medium"}
            >
              {tech}
            </TypographyComponent>
            <TypographyComponent color={"coal"} boldness={"regular"} styling={"p3"}>
              {t(`Use our`)}
              {
                <TypographyComponent element={"span"} color={"coal"} boldness={"semi"}>
                  {t(` base template `)}
                </TypographyComponent>
              }
              {t(
                " for any technology evaluation to access all the same features to manage your evaluation, including a comprehensive checklist, universal requirements, questionnaire, evaluation scorecard and selection summary."
              )}
            </TypographyComponent>
          </div>
          <div className={classes.footer}>
            <ButtonLoader
              btnClass="btn-tertiary"
              disabled={isOtherTemplateDisabled || !isEntitled}
              isLoading={isLoading}
              onClick={handleClickStartEval}
              rootClassName={classNames(
                "gx-card-action",
                "gx-card-use-base-template",
                "gx-intercom-card-use-base-template"
              )}
            >
              {t("Start eval from scratch")}
            </ButtonLoader>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default BaseTemplateCard;
