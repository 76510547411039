import { colorPalette, weightPalette } from "gx-npm-common-styles";

const cardClasses = {
  container: {
    backgroundColor: colorPalette.basic.white.hex,
    borderColor: colorPalette.basic.gray.hex,
    borderRadius: 8,
    borderWidth: 1,
    boxSizing: "border-box" as const,
    color: colorPalette.basic.black.hex,
    display: "flex",
    flexDirection: "column" as const,
    fontSize: "14px",
    height: "260px",
    justifyContent: "space-between",
    padding: "24px 32px 32px 32px",
    transition: "all 0.5s",
    width: "100%",
    "@media (max-width: 1239px)": {
      height: "273px",
    },
    "@media (min-width: 1240px) and (max-width: 1439px)": {
      height: "394px",
    },
    "@media (min-width: 1440px) and (max-width: 1919px)": {
      height: "294px",
    },
    "@media (min-width: 1920px)": {
      height: "260px",
    },
  },
  bodyContainer: {
    // overflow is specifically not hidden in the body of the cards per GCOM-498
    overflow: "visible",
  },
  category: {
    color: colorPalette.basic.textSecondary.rgba,
    paddingBottom: 8,
  },
  description: {
    color: colorPalette.neutrals.coal.hex,
  },
  tech: {
    marginBottom: 0,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (min-width: 1240px) and (max-width: 1439px)": {
      display: "flex",
      flexDirection: "column" as const,
      "& .gx-btn-loader": {
        marginLeft: 0,
        "&.gx-card-action": {
          marginLeft: 0,
          marginTop: "35px",
        },
      },
    },
  },
  typeTitle: {
    paddingBottom: "8px",
  },
  templateContent: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "8px",
  },
  templateContentList: {
    listStyle: "none",
    padding: "0px",
    margin: "0px",
    "& li:last-child": {
      borderRight: "none",
    },
  },
  templateContentListItem: {
    float: "left" as const,
    marginRight: "12px",
    paddingRight: "12px",
    borderRight: `1px solid ${colorPalette.neutrals.silver.hex}`,
    fontSize: "12px",
    color: colorPalette.neutrals.iron.hex,
    lineHeight: "18px",
  },
  templateItemCount: {
    color: colorPalette.neutrals.carbon.hex,
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
  },
  marketDescription: {
    marginTop: 8,
  },
};

export default cardClasses;
