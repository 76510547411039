import React, { useContext } from "react";
import styles from "./suggested-market-banner.styles.module.scss";
import classNames from "classnames";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { SUGGESTED_CATEGORY_ID, SUGGESTED_CATEGORY_TITLE } from "../../../app.constants";
import { useUserState } from "gx-npm-lib";
import { suggestedMarketData } from "../../../lib/suggested-market-data";
import { AppContext } from "../../../app.context";

const SuggestedMarketBanner = () => {
  const { t } = useTranslation();
  const { setCategoryId, setSelectedCategoryTemplates, templateData, setCategoryName, setIsSearchData } =
    useContext(AppContext);
  const { profile } = useUserState();

  const handleNavigate = () => {
    const categoryName = t(SUGGESTED_CATEGORY_TITLE);
    setCategoryId(SUGGESTED_CATEGORY_ID);
    setCategoryName(categoryName);
    setIsSearchData(false);
    window.scrollTo({ top: 0 });
    const suggestedTemplates = templateData?.filter(
      (template) => suggestedMarketData[profile.role]?.includes(template.id)
    );
    setSelectedCategoryTemplates(suggestedTemplates);
  };
  return (
    <div className={classNames(styles.suggestedBanner)}>
      <div className={classNames(styles.bannerHeader)}>
        <TypographyComponent styling={"h4"} boldness={"medium"}>
          {t("Personalized market suggestions")}
        </TypographyComponent>
        <TypographyComponent styling={"p3"} rootClassName={classNames(styles.bannerParagraph)} boldness={"regular"}>
          {t("Explore markets users like you are leveraging for their technology evaluations.")}
        </TypographyComponent>
      </div>

      <Button rootClassName={classNames("btn-primary", styles.bannerButton)} onClick={handleNavigate}>
        {t("Get suggested markets")}
      </Button>
    </div>
  );
};
export default SuggestedMarketBanner;
