import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import PlaneIllustration from "../../../../assets/images/plane-illustration.svg";
import styles from "./no-template-search-results.styles";
import { AppContext } from "../../../../app.context";
import { TypographyComponent } from "gx-npm-ui";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const NoTemplateSearchResults = () => {
  const { searchTerm } = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.noResultsContainer}>
        <TypographyComponent boldness={"semi"} styling={"h3"}>
          <TypographyComponent element={"span"} boldness={"medium"} color={"carbon"}>
            {t("We were unable to find any markets for")}
            <br />
          </TypographyComponent>
          <TypographyComponent element={"span"} boldness={"medium"} color={"carbon"}>
            {searchTerm}
          </TypographyComponent>
        </TypographyComponent>

        <TypographyComponent
          styling={"p1"}
          boldness={"semi"}
          color={"iron"}
          rootClassName={classNames(classes.noResultsSubtitle)}
        >
          {t("Please try again with a different keyword or vendor name.")}
        </TypographyComponent>
      </div>
      <div className={classes.airplaneContainer}>
        <img alt="" src={PlaneIllustration} />
      </div>
    </Fragment>
  );
};

export default NoTemplateSearchResults;
