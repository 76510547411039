import { colorPalette, weightPalette } from "gx-npm-common-styles";

const templateSearchInputStyle = {
  container: {
    alignItems: "center",
    background: colorPalette.basic.white.hex,
    borderBottom: `2px solid ${colorPalette.neutrals.pearl.hex}`,
    display: "flex",
    height: "56px",
    width: "100%",
  },
  inputContainer: {
    height: "48px",
    marginLeft: "4px",
    width: "240px",
    "&.focused, &.hovered, &.populated": {
      border: `1px solid ${colorPalette.neutrals.stone.hex}`,
      marginLeft: "3px",
      "& $inputField": {
        height: "46px",
      },
    },
    "&.hovered.populated:not(.focused)": {
      border: `1px solid ${colorPalette.neutrals.carbon.hex}`,
      marginLeft: "3px",
      "& $inputField": {
        height: "46px",
      },
    },
    "&.focused": {
      border: `1px solid ${colorPalette.interactions.defaultCta.hex}`,
      caretColor: colorPalette.interactions.defaultCta.hex,
    },
  },
  inputField: {
    flexDirection: "row" as const,
    height: "48px",
    "& input": {
      border: "unset",
      color: colorPalette.neutrals.carbon.hex,
      display: "inline-flex",
      fontSize: "14px",
      fontVariationSettings: weightPalette.medium.variation,
      fontWeight: weightPalette.medium.amount,
      lineHeight: "21px",
      letterSpacing: "0.25px",
      padding: "4px 4px 4px 16px",
      width: "240px",
      "&.has-search-term": {
        width: "216px",
      },
      "&::placeholder": {
        color: colorPalette.neutrals.iron.hex,
        fontVariationSettings: weightPalette.regular.variation,
        fontWeight: weightPalette.regular.amount,
      },
    },
    "& .MuiInputBase-input": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  searchButton: {
    alignItems: "center",
    display: "flex",
    height: "56px",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "0",
    width: "56px",
  },
};

export default templateSearchInputStyle;
